// ============================
//    3. Sidebar css start
// ============================

.pc-sidebar {
  background: var(--pc-sidebar-background);
  width: $sidebar-width;
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 1026;
  overflow: hidden;
  box-shadow: var(--pc-sidebar-shadow);
  border-right: var(--pc-sidebar-border);

  .navbar-wrapper {
    width: $sidebar-width;
    background: inherit;
  }
  ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
  }
  .pc-caption {
    color: var(--pc-sidebar-caption-color);
    display: block;
    padding: 24px 6px 10px !important;
    text-transform: capitalize;
    position: relative;
    line-height: 1.34;

    &:first-child {
      padding-top: 0 !important;
    }
    font: {
      size: 14px;
      weight: 500;
    }
    svg,
    i {
      display: none;
    }
    label {
      margin-bottom: 0;
    }
    span:not(.badge) {
      display: block;
      color: #67758a;

      font: {
        size: 14px;
        weight: 500;
      }
    }
  }
  .pc-micon {
    margin-right: 15px;
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: top;
    text-align: center;

    i {
      font-size: 18px;

      &.material-icons-two-tone {
        font-size: 22px;
        display: inline-block;
      }
    }

    > svg {
      width: 18px;
      height: 18px;
      display: inline-block;
    }
  }
  .pc-mtext {
    text-transform: capitalize;
  }
  .pc-link {
    display: block;
    padding: 13px 20px;
    color: var(--pc-sidebar-color);
    position: relative;
    font: {
      size: 14px;
      weight: 400;
    }
    &:focus,
    &.active,
    &:hover {
      text-decoration: none;
    }
  }

  .pc-navbar > .pc-item {
    margin: 0 16px;
    > .pc-link {
      &::after {
        content: '';
        border-radius: var(--bs-border-radius);
        position: absolute;
        top: 2px;
        right: 2px;
        left: 2px;
        bottom: 2px;
        opacity: 0.1;
      }
    }
    &.active {
      > .pc-link {
        font-weight: 500;
        color: var(--pc-sidebar-active-color);
        &:after {
          background: var(--pc-sidebar-active-color);
        }
      }
    }
    &:hover:not(.active) {
      > .pc-link {
        &:after {
          background: var(--pc-sidebar-active-color);
        }
      }
    }
    .pc-submenu {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 30px;
        height: 100%;
        width: 1px;
        background: var(--pc-sidebar-submenu-border-color);
      }
      .pc-item {
        > .pc-link {
          &:after {
            content: '';
            position: absolute;
            top: 20px;
            left: 45px;
            height: 5px;
            width: 5px;
            border-radius: 50%;
            background: var(--pc-sidebar-submenu-border-color);
          }
        }
        &.pc-trigger,
        &.active {
          > .pc-link {
            font-weight: 500;
            color: var(--pc-sidebar-active-color);
          }
        }
        &.pc-trigger,
        &.active,
        &:focus,
        &:hover {
          > .pc-link {
            &:after {
              background: var(--pc-sidebar-active-color);
            }
          }
        }
      }
    }
    .pc-submenu {
      &:after {
        left: 30px;
      }
      .pc-link {
        padding: 12px 30px 12px 60px;
        &:after {
          left: 28px;
        }
      }
      .pc-submenu {
        &:after {
          left: 46px;
        }
        .pc-link {
          padding: 12px 30px 12px 80px;
          &:after {
            left: 62px;
          }
        }

        .pc-submenu {
          &:after {
            left: 63px;
          }
          .pc-link {
            padding: 12px 30px 12px 95px;
            &:after {
              left: 79px;
            }
          }
        }
      }
    }
  }
  .pc-item {
    &.disabled {
      a {
        cursor: not-allowed;
        user-select: none;
      }
    }
  }
  .pc-arrow {
    float: right;
    display: inline-block;
    transition: all 0.2s ease-in-out;
    position: relative;

    > svg {
      width: 14px;
      height: 14px;
    }
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
    }
  }
  .pc-badge {
    margin-right: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    font-size: 10px;
    line-height: 1;
    float: right;
    background: var(--bs-primary);
  }
  .pc-navbar-card {
    position: relative;
    overflow: hidden;
    padding: 24px;
    margin: 20px;

    &::before {
      border: 3px solid;
      top: 145px;
      right: -70px;
    }

    &::after {
      border: 19px solid;
      top: 65px;
      right: -150px;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      border-color: rgba(255, 255, 255, 0.5);
      border-radius: 50%;
      width: 200px;
      height: 200px;
    }
  }
  .m-header {
    height: $header-height;
    display: flex;
    align-items: center;
    padding: 16px 24px;

    .logo-sm {
      display: none;
    }
  }
  .navbar-content {
    position: relative;
    height: calc(100vh - #{$header-height});
    padding: 10px 0;
  }

  .pc-hasmenu {
    &:not(.pc-trigger) {
      > .pc-submenu {
        display: none;
      }
    }
    &.pc-trigger {
      > .pc-submenu {
        display: block;
      }

      > .pc-link {
        > .pc-arrow {
          transform: rotate(90deg);
        }
      }
    }
  }
}

.pc-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  backdrop-filter: blur(3px);
  background: rgba(0, 0, 0, 0.15);
}
[data-pc-sidebar-caption='false'] {
  .pc-sidebar .pc-caption {
    display: none;
  }
}
@media (min-width: 1025px) {
  .navbar-overlay {
    .pc-sidebar {
      &.pc-over-menu-active {
        .navbar-wrapper {
          position: relative;
          z-index: 5;
        }
      }

      &:not(.pc-over-menu-active) {
        left: -#{$sidebar-width};
        box-shadow: none;
      }
    }
  }
  .pc-sidebar {
    transition: width 0.2s ease;
    ~ .pc-header {
      transition: left 0.2s ease;
    }
    ~ .pc-footer,
    ~ .pc-container {
      transition: margin-left 0.2s ease;
    }
    &.pc-sidebar-hide {
      width: 0;
      --pc-sidebar-border: none;
      ~ .pc-header {
        left: 0;
      }
      ~ .pc-footer,
      ~ .pc-container {
        margin-left: 0px;
      }
    }
  }
  .pc-header .pc-h-item.pc-sidebar-popup {
    display: none;
  }
}
@media (max-width: 1024px) {
  .pc-header .pc-h-item.header-mobile-collapse {
    display: none;
  }
  .pc-sidebar {
    left: -#{$sidebar-width};
    box-shadow: none;
    top: 0;
    transition: all 0.2s ease-in-out;
    &.mob-sidebar-active {
      left: 0;

      .navbar-wrapper {
        position: relative;
        z-index: 5;
        background: inherit;
      }
    }
  }
}
.layout-2 {
  --pc-sidebar-background: var(--bs-body-bg);
  --pc-header-background: var(--bs-body-bg);

  .pc-container {
    background: rgba(255, 255, 255, 0.6);
    .page-header,
    .card {
      border: 1px solid var(--bs-border-color);
    }
  }
}
// ============================
//    3. Sidebar css end
// ============================
