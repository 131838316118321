/**  =====================
   22. Pages css start
==========================  **/
@import 'price';

.contact-details {
  position: relative;

  .contact-mail {
    position: absolute;
    bottom: -210px;
    right: 98px;
    width: 400px;
    max-width: 100%;
    animation: 5s ease-in-out 0s infinite normal none running wings;
  }
}

.contact-card {
  margin-top: 106px;

  .contact-footer {
    display: flex;
    justify-content: space-between;

    p {
      span {
        color: var(--bs-primary);
        text-decoration: underline;
      }
    }
  }
}

.faq-card {
  margin-top: 105px;
}

.faq-details {
  position: relative;

  .faq-mail {
    position: absolute;
    bottom: -210px;
    right: 98px;
    width: 400px;
    max-width: 100%;
    animation: 5s ease-in-out 0s infinite normal none running wings;
  }
}

.navbar.bg-white {
  z-index: 99;
  padding: 10px 0;

  .nav-item {
    .nav-link {
      padding-right: 15px;
    }
  }
}

.btns-gallery {
  .btn-light-primary {
    &:not(:hover),
    &:not(:focus),
    &:not(:active),
    &:not(.active) {
      color: var(--pc-heading-color);
      background: transparent;
      border-color: transparent;
    }
  }
}
.card-gallery {
  position: relative;
  border-radius: var(--bs-border-radius);
  overflow: hidden;
  display: block;

  .img-fluid {
    transition: all 0.2s ease-in-out;
  }

  .gallery-hover-data {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(2px);
    opacity: 0;
    transform: scale(0.4);
    transition: all 0.2s ease-in-out;
    .prod-likes .prod-likes-icon {
      stroke: rgba(255, 255, 255, 0.5);
      fill: rgba(255, 255, 255, 0.2);
    }
    .wid-30 {
      min-width: 25px;
    }
  }
  &:hover {
    .img-fluid {
      transform: scale(1.3);
    }
    .gallery-hover-data {
      transform: scale(1);
      opacity: 1;
    }
  }
}

.front-header-image {
  position: relative;
  margin-top: 75px;

  & > * {
    position: relative;
    z-index: 5;
  }

  .bg-img-overlay {
    position: fixed;
    background-size: cover;
    height: 500px;
    width: 100%;
    top: 0;
    left: 0;
  }

  p {
    span {
      color: var(--bs-primary);
    }
  }
}

// invoice start
.table-invoice-comp {
  border-spacing: 0 10px;
  border-collapse: separate;
  width: calc(100% - 10px);
  margin: 0 5px;
  tbody {
    tr {
      box-shadow: var(--pc-card-shadow);
      background: var(--bs-card-bg);
      border-radius: 4px;
      position: relative;
    }
  }
}

.invoice-total {
  width: 100%;
  max-width: 400px;
}

/**  =====================
   22. Pages css end
==========================  **/
