/**  =====================
      Kanban css start
==========================  **/
.pc-kanban-wrapper {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  &::-webkit-scrollbar {
    height: 6px;
    opacity: 0;
    &:hover {
      opacity: 1;
    }
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: darken($body-bg, 05%);
  }
  &::-webkit-scrollbar-thumb:hover {
    background: darken($body-bg, 25%);
  }
  .pc-kanban-column {
    background: rgba(var(--bs-primary-rgb), 0.175);
    border-radius: $border-radius;
    width: 250px;
    flex: 0 0 auto;
    max-width: 100%;
    margin-bottom: 12px;
    &:not(:last-child) {
      margin-right: var(--bs-gutter-x);
    }
    .card {
      margin-bottom: 16px;
      .card-body {
        padding: 16px;
      }
    }
  }
  .pc-kanban-cards {
    padding: 16px 0;
  }
  .pc-kanban-header {
    display: flex;
    align-items: center;
    padding: 16px;
    .form-control {
      &:not(:focus) {
        border-color: transparent;
        background: transparent;
        box-shadow: none;
      }
    }
  }
  .pc-kanban-body {
    padding: 0 16px 16px;
    height: calc(100vh - 480px);
  }
}
.btn-collpsed:not(.collapsed) {
  transform: rotate(90deg);
}
.tbl-backlog {
  tr:not(:hover) {
    .drp-suboption {
      opacity: 0;
    }
  }
}
// Kanban css end
