/**  =====================
  36. Customizer css start
==========================  **/
.pct-c-btn {
  display: block;
  padding: 0px;
  border-right: none;
  position: fixed;
  overflow: hidden;
  right: 10px;
  top: 200px;
  z-index: 1030;
  transition: all 0.15s ease-in-out;
  border-radius: 50% 50% 4px 50%;
  box-shadow: 0 12px 14px 0 rgba(var(--bs-secondary-rgb), 0.3);
  background: var(--bs-secondary);

  a {
    padding: 0;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.08s cubic-bezier(0.37, 0.24, 0.53, 0.99);

    i {
      font-size: 24px;
      display: block;
      animation: anim-rotate 2.5s infinite linear;
      line-height: 24px;
      color: #fff;
    }

    &:hover {
      background-color: rgba(var(--bs-primary-rgb), 0.1);
    }
  }

  @media (max-width: 1024px) {
    display: none;
  }
}

@keyframes anim-rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.pct-offcanvas {
  --bs-offcanvas-width: 375px;
  --bs-offcanvas-zindex: 1079;

  transition: transform 0.45s cubic-bezier(0.37, 0.24, 0.53, 0.99);
  box-shadow: 8px 0 14px rgba(27, 46, 94, 0.4);

  ~ .offcanvas-backdrop {
    opacity: 0.2;
  }

  .offcanvas-body {
    padding: 25px;
  }
  h5 {
    text-transform: uppercase;
  }
  .avtar.avtar-xs {
    width: 36px;
    height: 36px;
  }

  .list-group-item {
    padding: 16px;

    > div > .pct-content,
    a.btn {
      padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
    }
  }
}

.pct-tabs {
  background: rgba(var(--bs-primary-rgb), 0.06);
  border-top: 1px solid var(--bs-border-color);
  border-bottom: 1px solid var(--bs-border-color);

  .nav-link {
    border-radius: 0px;
    background: transparent;
    font-size: 24px;
    border: none;
    color: var(--bs-body-color);
    position: relative;
    padding: 12px 16px;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 1px;
      height: 0;
      background: var(--bs-primary);
    }
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    background: transparent;
    color: var(--bs-primary);

    &::after {
      height: 2px;
    }
  }
}

.preset-btn {
  border: 2px solid var(--bs-gray-300);
  --bs-btn-active-border-color: var(--bs-gray-300);

  &:hover {
    border-color: var(--bs-primary);
  }

  &:active,
  &.active {
    border-color: var(--bs-primary);
  }
}

.theme-color.preset-color {
  display: flex;
  position: relative;
  padding: 0;
  width: 100%;
  flex-wrap: wrap;
  padding: 0 8px 8px;

  > a {
    position: relative;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    transition: all 0.15s ease-in-out;
    margin-right: 10px;
    margin-top: 10px;
    width: 48px;
    height: 48px;
    flex: none;

    &::after {
      content: '';
      position: absolute;
      top: 4px;
      left: 4px;
      right: 4px;
      bottom: 4px;
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.5);
      z-index: 1;
      transform: scale(0);
      transition: all 0.15s ease-in-out;
    }

    i {
      font-size: 27px;
      font-weight: 600;
      color: #fff;
      transform: scale(0);
      transition: all 0.15s ease-in-out;

      &::before {
        position: relative;
        z-index: 5;
      }
    }

    &:hover {
      &::after {
        transform: scale(1);
      }
    }

    &.active {
      &::after,
      i {
        transform: scale(1);
      }
    }
  }

  &.preset-color {
    $i: 1;

    @each $name, $value in $preset-colors {
      > a {
        &[data-value='preset-#{$i}'] {
          background: linear-gradient(135deg, map-get($value, 'primary') 50%, map-get($value, 'secondary') 50%);
        }
      }

      $i: $i + 1;
    }
  }
}

.theme-color {
  .btn-label {
    margin: 5px 0 14px 20px;
    position: relative;
    display: block;
    text-align: left;

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: -20px;
      top: -1px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
    }

    &::before {
      background: var(--bs-primary);
      transform: scale(0);
    }

    &::after {
      border: 2px solid var(--bs-border-color);
    }
  }

  .pc-lay-icon {
    position: relative;
    width: 35px;
    height: 25px;
    border-radius: 3px;
    display: inline-block;
    background: var(--bs-body-bg);
    overflow: hidden;
    border: 1px solid var(--bs-border-color);

    span {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;

      &:nth-child(1),
      &:nth-child(3) {
        width: 32%;
        left: 0;
      }

      &:nth-child(1) {
        background: var(--pc-sidebar-background);
      }

      &:nth-child(3) {
        background: var(--pc-sidebar-background);
      }

      &:nth-child(2),
      &:nth-child(4) {
        z-index: 1;
        width: 70%;
        left: auto;
        right: 0;
        background: var(--bs-body-bg);
      }

      &:nth-child(1),
      &:nth-child(2) {
        top: 0;
        height: 35%;
      }

      &:nth-child(3),
      &:nth-child(4) {
        top: auto;
        bottom: 0;
        height: 75%;
      }

      &:nth-child(2) {
        background: var(--pc-header-background);
      }
    }
  }
}

.theme-layout {
  .btn {
    i {
      font-size: 26px;
    }
  }
}

.customizer-body {
  position: relative;
  height: calc(100% - 140px);
}

.preset-btn {
  padding: 3px;
  width: 100%;
  line-height: 0;
  border-radius: 7px;

  .pc-lay-icon {
    width: 100%;
    height: 35px;
    min-width: 50px;
  }

  &.active {
    border-color: var(--bs-primary);

    .btn-label {
      &::before {
        background: var(--bs-primary);
        transform: scale(0.5);
      }

      &::after {
        border-color: var(--bs-primary);
      }
    }
  }
}

.theme-layout {
  .btn[data-value='false'] {
    .pc-lay-icon {
      span {
        &:nth-child(1),
        &:nth-child(3) {
          background: lighten($dark, 8%);
        }

        &:nth-child(2),
        &:nth-child(4) {
          background: $dark;
        }
      }
    }
  }

  .btn[data-value='true'] {
    .pc-lay-icon {
      span {
        &:nth-child(1),
        &:nth-child(3) {
          background: var(--bs-white);
        }

        &:nth-child(2),
        &:nth-child(4) {
          background: $body-bg;
        }
      }
    }
  }
}

.theme-sidebar-color {
  .btn[data-value='false'] {
    span:not(.btn-label) {
      &:nth-child(1),
      &:nth-child(3) {
        background: var(--bs-white);
      }
    }
  }

  .btn[data-value='true'] {
    .pc-lay-icon {
      span {
        &:nth-child(1),
        &:nth-child(3) {
          background: var(--bs-dark);
        }
      }
    }
  }
}

.theme-nav-caption {
  .btn span {
    &:nth-child(3) {
      flex-direction: column;

      span {
        position: absolute;
        background: var(--pc-sidebar-active-color);
        height: 4px;
        width: 40% !important;
        left: 0 !important;
        border-radius: 3px;

        &:before,
        &:after {
          border-radius: 3px;
          content: '';
          position: absolute;
          left: 0;
          background: var(--pc-sidebar-color);
          height: 3px;
          width: 150%;
        }

        &:before {
          top: 5px;
        }

        &:after {
          top: 10px;
        }

        &:nth-child(2) {
          top: 15px;
        }
      }
    }
  }

  .btn[data-value='false'] span {
    &:nth-child(3) {
      span {
        background: transparent;
      }
    }
  }
}

.theme-direction {
  .btn[data-value='true'] {
    .pc-lay-icon {
      span {
        &:nth-child(1),
        &:nth-child(3) {
          left: auto;
          right: 0;
        }

        &:nth-child(2),
        &:nth-child(4) {
          left: 0;
          right: auto;
        }
      }
    }
  }
}

.theme-container {
  .btn span {
    &:nth-child(4) {
      padding: 5px;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        background: rgba(0, 0, 0, 0.15);
        height: 100%;
        width: 100%;
      }

      > span {
        border-radius: 3px;
        position: relative;
        z-index: 5;
        background: var(--bs-offcanvas-bg);
        height: 100%;
        width: 100%;
      }
    }
  }

  .btn[data-value='true'] span {
    &:nth-child(4) {
      > span {
        width: 60%;
      }
    }
  }
}

.theme-font-style {
  padding: 8px;

  .form-check {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 8px;

    .form-check-label {
      padding: 13px 18px;
      border-radius: var(--bs-border-radius);
      border: 1px solid var(--bs-border-color);
      display: flex;
    }

    .form-check-input {
      display: none;

      &:checked {
        ~ .form-check-label {
          border-color: var(--bs-primary);
          box-shadow:
            0 0 0 1px rgba(var(--bs-primary-rgb), 1),
            0 0 0 6px rgba(var(--bs-primary-rgb), 0.12);
        }
      }
    }
  }
}

.pc-box-width {
  @include media-breakpoint-down(xl) {
    display: none;
  }
}

[data-pc-layout='compact'] [data-pc-layout='horizontal'] {
  .pc-rtl {
    display: none;
  }
}

.auth-main {
  ~ .pct-offcanvas {
    .pc-boxcontainer {
      display: none;
    }
  }
}

/**  =====================
  36. Customizer css end
==========================  **/
